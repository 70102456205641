type VideoData = {
  title: string;
  source: string;
  mdDelay: number;
  smDelay: number;
};

export const FieldVideos: VideoData[] = [
  {
    title: '',
    source:'',
    smDelay:0,
    mdDelay:0,
  },
  {
    title: 'Cherries from Field to Packing',
    source:`https://www.youtube.com/embed/${'twfYVSIbTXg'}?rel=0`,
    smDelay:0,
    mdDelay:0,
  },
  {
    title: '',
    source:'',
    smDelay:0,
    mdDelay:0,
  },
  {
    title: 'All Crops Labor Management',
    source: `https://www.youtube.com/embed/${'IAwAgRUQQ9Y'}?rel=0`,
    smDelay: 0,
    mdDelay: 0,
  },
  {
    title: 'Learn More about PET Tiger',
    source: `https://www.youtube.com/embed/${'kOOKIl9RbD4'}?rel=0`,
    smDelay: 100,
    mdDelay: 100,
  },
  {
    title: 'PET Tiger in Spanish',
    source: `https://www.youtube.com/embed/${'4NiwV-xXN3c'}?rel=0`,
    smDelay: 50,
    mdDelay: 200,
  },
  {
    title: 'Scan and Piece Out Process',
    source: `https://www.youtube.com/embed/${'OLOat8g9h6Q'}?rel=0`,
    smDelay: 150,
    mdDelay: 50,
  },
  {
    title: 'Complete Process in the Field',
    source: `https://www.youtube.com/embed/${'WENGcUzHkR8'}?rel=0`,
    smDelay: 100,
    mdDelay: 150,
  },
  {
    title: 'Face Scan Clock',
    source: `https://www.youtube.com/embed/${'NSDl5QBbg24'}?rel=0`,
    smDelay: 200,
    mdDelay: 250,
  },

  {
    title: 'Scan Wall Clock',
    source: `https://www.youtube.com/embed/${'3iPIfgPFeao'}?rel=0`,
    smDelay: 150,
    mdDelay: 100,
  },
  {
    title: 'Time & Productivity',
    source: `https://www.youtube.com/embed/${'O7Gzpqo9a_Q'}?rel=0`,
    smDelay: 250,
    mdDelay: 200,
  },
  {
    title: 'Scan Device w/ Printer',
    source: `https://www.youtube.com/embed/${'opdTMQQEhKE'}?rel=0`,
    smDelay: 300,
    mdDelay: 300,
  },
  {
    title: 'Intuitive Process',
    source: `https://www.youtube.com/embed/${'jLULfU5j520'}?rel=0`,
    smDelay: 400,
    mdDelay: 150,
  },
  {
    title: 'Complete Transparency',
    source: `https://www.youtube.com/embed/${'6ZQcQQ7HPVU'}?rel=0`,
    smDelay: 350,
    mdDelay: 250,
  },
  {
    title: 'Best Solution for Agriculture',
    source: `https://www.youtube.com/embed/${'6HSPEMA6FC4'}?rel=0`,
    smDelay: 450,
    mdDelay: 350,
  }, 
  {
    title: 'Cherry Harvest',
    source: `https://www.youtube.com/embed/${'lGZaZwICQNM'}?rel=0`,
    smDelay: 500,
    mdDelay: 200,
  },
  {
    title: 'Table Grape Harvest',
    source: `https://www.youtube.com/embed/${'QNahRUd-RxI'}?rel=0`,
    smDelay: 450,
    mdDelay: 300,
  },
  {
    title: 'Blueberry Harvest',
    source: `https://www.youtube.com/embed/${'g-lUyXUguNQ'}?rel=0`,
    smDelay: 550,
    mdDelay: 400,
  },

  // {
  //   title: "Batch Scan on Mobile",
  //   source: `https://www.youtube.com/embed/${"900w7SeoecU"}?rel=0`,
  //   mdDelay: 400,
  //   smDelay: 100
  // },
];

export const LandingVideo: VideoData = {
  title: 'PET Tiger Intro',
  source: `https://www.youtube.com/embed/${'337peus98-E'}?rel=0`,
  mdDelay: 0,
  smDelay: 0,
};
