import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import orangeLogo from '../assets/images/orangeLogo.png';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import { deepOrange, blue } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';
// import {Menu, MenuItem} from "@material-ui/core";
// import {AccountCircle} from "@material-ui/icons";
// import { Redirect } from "react-router-dom";


interface Props {
  window?: () => Window;
  children?: React.ReactElement;
}

interface ScrollProps {
  window?: () => Window;
  children?: React.ReactElement;
  isSm: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    navItems: {
      // flexGrow: 1,
      marginLeft: 'auto',
    },
    menuItem: {
      marginRight: theme.spacing(3),
      '&:hover': {
        cursor: 'pointer',
      },
      // marginLeft: 10,
    },
    link: {
      color: deepOrange[50],
      marginLeft: 30,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
    titleLink: {
      color: deepOrange[50],
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
    avatar: {
      backgroundColor: blue[500],
      backgroundradius: '10px',
    },
  })
);

export const Navbar = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  //
  // const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  //
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');
  
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  
  const ScrollTop = (props: ScrollProps) => {
    const { children, window, isSm } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: isSm ? 250 : 3000,
    });

    return (
      <Zoom in={trigger}>
        <div
          onClick={e => {
            history.push('/');
            handleClick(e);
          }}
          role='presentation'
          className={classes.root}
        >
          {children}
        </div>
      </Zoom>
    );
  }

  // const mobileMenu = () => (
  //   <Menu
  //     id="menu-appbar"
  //     anchorEl={anchorEl}
  //     anchorOrigin={{
  //       vertical: 'top',
  //       horizontal: 'right',
  //     }}
  //     keepMounted
  //     transformOrigin={{
  //       vertical: 'top',
  //       horizontal: 'right',
  //     }}
  //     open={open}
  //     onClose={handleClose}
  //   >
  //     <MenuItem onClick={() => {
  //       handleClose();
  //       history.push('references');
  //     }}>References</MenuItem>
  //     <MenuItem onClick={() => {
  //       handleClose();
  //       return <Redirect to={{ pathname: '/', hash: '#contact'}}/>;
  //     }}>Contact Us</MenuItem>
  //     <MenuItem onClick={() => {
  //       handleClose();
  //       window.open('https://orangeenterprises1.azurewebsites.net', "_blank");
  //     }}>Client Login</MenuItem>
  //   </Menu>
  // )
  //
  // const mobileIcon = () => (
  //   <IconButton
  //     aria-label="account of current user"
  //     aria-controls="menu-appbar"
  //     aria-haspopup="true"
  //     onClick={handleMenu}
  //     color="inherit"
  //   >
  //     <AccountCircle />
  //   </IconButton>
  // )

  const mainMenu = () => (
    <div className={classes.navItems}>
      <Typography variant={isXs ? 'overline' : 'h6'} align='right' display={isXs ? 'block' : 'inline'} className={classes.title} noWrap>
        <Link href={`/references`} color='textSecondary' component='a' className={classes.link}>
          References
        </Link>
      </Typography>
      <Typography variant={isXs ? 'overline' : 'h6'} align='right' display={isXs ? 'block' : 'inline'} className={classes.title} noWrap>
        <Link href={`#contact`} color='textSecondary' component='a' className={classes.link}>
          Contact Us
        </Link>
      </Typography>
      <Typography variant={isXs ? 'overline' : 'h6'} align='right' display={isXs ? 'block' : 'inline'} className={classes.title} noWrap>
        <Link href='https://orangeenterprises1.azurewebsites.net' target='_blank' color='textSecondary' component='a' className={classes.link}>
          Client Login
        </Link>
      </Typography>
    </div>
  )

  return (
    <React.Fragment>
      <AppBar>
        <Grid container>
          <Grid item xs={false} lg={1} />
          <Grid item xs={12} lg={10}>
            <Toolbar>
              <div
                onClick={e => {
                  history.push('/');
                  handleClick(e);
                }}
              >
                <IconButton
                  edge='start'
                  className={classes.menuButton}
                  color='inherit'
                  aria-label='menu'
                >
                  <Avatar
                    variant='rounded'
                    alt='Remy Sharp'
                    src={orangeLogo}
                    className={classes.avatar}
                  />
                </IconButton>
              </div>

              <Typography variant='h5' className={classes.menuItem}>
                  <div
                    onClick={e => {
                      history.push('/');
                      handleClick(e);
                    }}
                  >
                  Orange Enterprises
                  </div>
              </Typography>

              {mainMenu()}
              {/*{mobileMenu()}*/}
            </Toolbar>
          </Grid>
          <Grid item xs={false} lg={1} />
        </Grid>
      </AppBar>
      <Toolbar id='back-to-top-anchor' />
      <ScrollTop {...props} isSm={isSm}>
        <Fab
          color='primary'
          size='small'
          aria-label='scroll back to top'
          style={{ marginBottom: `${isSm ? '180px' : '250px'}` }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
};
