import React, {useState} from "react";
import SwipeableViews from 'react-swipeable-views';
import {useTheme} from "@material-ui/core/styles";
import {Grid, Paper, useMediaQuery, Box} from "@material-ui/core";
import {TabPanel} from "./TabPanel";
import {TabViewProps} from './TabTypes';
import {Wrapper} from "../../components/Wrapper";
import {useTabStyles} from "./TabStyles";
import {lgTabs, mdTabs, smTabs, xsTabs} from "./TabMenus";

interface Props {
	content: TabViewProps[];
}

export const TabView = ({ content }: Props) => {
	const [value, setValue] = useState(0);
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('xs'));
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));
	const isMd = useMediaQuery(theme.breakpoints.down('md'));
	const classes = useTabStyles();
	const { tabLabel } = classes;

	const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index: number) => {
		setValue(index);
	};

	return (
		<div style={{backgroundColor: '#FFFFFF'}}>
			<Box p={[3, 5, 3, 5]} m={3}>
				<Wrapper px={3} spacing={2} alignContent="space-around">
					<Grid item xs={12}>
						<Paper className={classes.tabPaper} elevation={1}>
							{isXs
								? xsTabs({value, handleChange, content, tabLabel})
								: isSm
									? smTabs({value, handleChange, content, tabLabel})
									: isMd
										? mdTabs({value, handleChange, content, tabLabel})
										: lgTabs({value, handleChange, content, tabLabel})
							}
						</Paper>
					</Grid>
					<SwipeableViews
						axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
						index={value}
						onChangeIndex={handleChangeIndex}
					>
						{content.map(tab => (
							<TabPanel key={tab.order} value={value} index={tab.order} tab={tab}/>
						))}
					</SwipeableViews>
				</Wrapper>
			</Box>
		</div>
	);
};
