import React from "react";
import {Tab, Tabs} from "@material-ui/core";
import {TabViewProps} from "./TabTypes";

export interface TabMenuProps {
	value: number;
	handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
	content: TabViewProps[];
	tabLabel: string;
}

export const xsTabs = ({ value, handleChange, content, tabLabel }: TabMenuProps) => {
	return (
		<Tabs
			value={value}
			onChange={handleChange}
			indicatorColor="primary"
			textColor="primary"
			variant='fullWidth'
			centered
			selectionFollowsFocus
			orientation={"vertical"}
		>
			{content.map((tab, idx) => (
				<Tab key={idx} label={tab.label} value={tab.order} className={tabLabel} wrapped/>
			))}
		</Tabs>
	)
}

export const smTabs = ({ value, handleChange, content, tabLabel }: TabMenuProps) => {
	const firstSet: TabViewProps[] = [];
	const secondSet: TabViewProps[] = [];
	const thirdSet: TabViewProps[] = [];
	content.forEach((tab,idx) => {
		if (6 <= idx) {
			thirdSet.push(tab);
		} else if (3 <= idx) {
			secondSet.push(tab);
		} else {
			firstSet.push(tab);
		}
	})
	return (
		<>
			<Tabs
				value={value < 3 ? value : false}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant='fullWidth'
				centered
				selectionFollowsFocus
			>
				{firstSet.map((tab, idx) => (
					<Tab key={idx} label={tab.label} value={tab.order} className={tabLabel} wrapped/>
				))}
			</Tabs>
			<Tabs
				value={3 <= value && value < 6 ? value : false}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant='fullWidth'
				centered
				selectionFollowsFocus
			>
				{secondSet.map((tab, idx) => (
					<Tab key={idx} label={tab.label} value={tab.order} className={tabLabel} wrapped/>
				))}
			</Tabs>
			<Tabs
				value={6 <= value ? value : false}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant='fullWidth'
				centered
				selectionFollowsFocus
			>
				{thirdSet.map((tab, idx) => (
					<Tab key={idx} label={tab.label} value={tab.order} className={tabLabel} wrapped/>
				))}
			</Tabs>
		</>
	)
}

export const mdTabs = ({ value, handleChange, content, tabLabel }: TabMenuProps) => {
	const firstSet: TabViewProps[] = [];
	const secondSet: TabViewProps[] = [];
	content.forEach((tab,idx) => {
		if (5 <= idx) {
			secondSet.push(tab);
		} else {
			firstSet.push(tab);
		}
	})
	return (
		<>
			<Tabs
				value={value < 5 ? value : false}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant='fullWidth'
				centered
				selectionFollowsFocus
			>
				{firstSet.map((tab, idx) => (
					<Tab key={tab.order} label={tab.label} value={tab.order} className={tabLabel} wrapped/>
				))}
			</Tabs>
			<Tabs
				value={5 <= value ? value : false}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant='fullWidth'
				centered
				selectionFollowsFocus
			>
				{secondSet.map((tab, idx) => (
					<Tab key={tab.order} label={tab.label} value={tab.order} className={tabLabel} wrapped/>
				))}
			</Tabs>
		</>
	)
}

export const lgTabs = ({ value, handleChange, content, tabLabel }: TabMenuProps) => {
	return (
		<Tabs
			value={value}
			onChange={handleChange}
			indicatorColor="primary"
			textColor="primary"
			variant='scrollable'
			scrollButtons="on"
			// centered
			selectionFollowsFocus
			// classes={{
			// 	flexContainer: classes.tabGroup
			// }}
		>
			{content.map((tab, idx) => (
				<Tab key={idx} label={tab.label} value={tab.order} className={tabLabel} wrapped/>
			))}
		</Tabs>
	)
}