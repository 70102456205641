import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";

export const useTabStyles = makeStyles((theme: Theme) =>
	createStyles({
		headerMain: {
			marginBottom: '30px',
			[theme.breakpoints.down('xs')]: {
				marginLeft: '0px',
				marginRight: '0px',
				fontSize: '24px',
			},
		},
		headerSubtitle: {
			marginBottom: '20px',
			[theme.breakpoints.down('xs')]: {
				marginLeft: '0px',
				marginRight: '0px',
				fontSize: '16px',
			},
		},
		headerBody: {
			marginBottom: '30px',
			marginLeft: '200px',
			marginRight: '200px',
			[theme.breakpoints.down('md')]: {
				marginLeft: '50px',
				marginRight: '50px',
			},
			[theme.breakpoints.down('xs')]: {
				marginLeft: '0px',
				marginRight: '0px',
				fontSize: '16px',
			},
		},
		headerAction: {
			color: green[600],
			marginBottom: '0',
			fontWeight: 'bold',
			textDecoration: 'italics',
			[theme.breakpoints.down('xs')]: {
				marginLeft: '0px',
				marginRight: '0px',
				fontSize: '20px',
			},
		},
		tabLabel: {
			fontSize: "18px",
			minWidth: '100px',
			flex: 1,
		},
		tabPaper: {
			flexGrow: 1,
			marginBottom: 20,
			background: 'inherit'
		},
		tabTitle: {
			color: green[600],
			fontWeight: 'bold',
			marginBottom: '40px',
		},
		tabGroup: {
			// overflow: "auto",
			// whiteSpace: "normal",
			// display: "initial",
		},
		tabBody: {
			textDecoration: 'italics',
			marginBottom: '30px',
		},
		gridContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-around',
			overflow: 'hidden',
			backgroundColor: theme.palette.background.paper,
			marginRight: '10%',
			marginLeft: '10%',
			[theme.breakpoints.down('md')]: {
				marginRight: 0,
				marginLeft: 0,
			},
		},
		gridList: {
			transform: 'translateZ(0)',
			overflow: 'hidden',
		},
		gridItem: {
			minHeight: '250px',
			padding: 20,
		},
		gridTitle: {
			color: theme.palette.primary.light,
		},
		gridTitleBar: {
			background: 'linear-gradient(to top, rgba(0,0,0,0.6) 30%, rgba(0,0,0,0.6) 80%, rgba(0,0,0,0) 100%)',
		},
	})
)