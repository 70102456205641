import {ImageData} from "./Images";
// A Group
import anthonyVineyards from "../assets/images/RefPageImages/A-Anthony Vineyards.jpg";
import bayer from "../assets/images/RefPageImages/A-Bayer.jpg";
import capayOrganicFarms from "../assets/images/RefPageImages/A-Capay Organic farm.jpg";
import grimmwayFarms from "../assets/images/RefPageImages/A-Grimmway Farms.jpg";
import harrisRanch from "../assets/images/RefPageImages/A-Harris Ranch.jpg";
import LandM from "../assets/images/RefPageImages/A-L&M.jpg";
import olam from "../assets/images/RefPageImages/A-Olam.jpg";
import planasa from "../assets/images/RefPageImages/A-Planasa.jpg";
import wonderful from "../assets/images/RefPageImages/A-Wonderful Company.jpg";
// B Group
import agroLabor from "../assets/images/RefPageImages/B-Agro Labor Services.jpg";
import agSocio from "../assets/images/RefPageImages/B-AgSocio.jpg";
import calCentral from "../assets/images/RefPageImages/B-Cal Central Harvesting Inc.jpg";
import coastalFarm from "../assets/images/RefPageImages/B-Coastal Farm Labor Services.jpg";
import corazonDelCampo from "../assets/images/RefPageImages/B-CORAZON DEL CAMPO.jpg";
import moonridge from "../assets/images/RefPageImages/B-Moonridge Management Inc.jpg";
import premiumPacking from "../assets/images/RefPageImages/B-Premium Packing.jpg";
import ramco from "../assets/images/RefPageImages/B-RAMCO.jpg";
import sanLucasAg from "../assets/images/RefPageImages/B-San Lucas AG Service.jpg";
import santaMaria from "../assets/images/RefPageImages/B-Santa Maria Harvesting.jpg";
import castaneda from "../assets/images/RefPageImages/B-The Castaneda & Sons, Inc.jpg";
import titan from "../assets/images/RefPageImages/B-Titan Ag Services, Inc.jpg";
// C Group
import acquistapace from "../assets/images/RefPageImages/C-Acquistapace Harvesting, Inc.jpg";
import andyBoy from "../assets/images/RefPageImages/C-Andy Boy.jpg";
import bonipak from "../assets/images/RefPageImages/C-Bonipak.jpg";
import cedarPoint from "../assets/images/RefPageImages/C-Cedar Point Nursery.jpg";
import centralWest from "../assets/images/RefPageImages/C-Central West Produce.jpg";
import dalena from "../assets/images/RefPageImages/C-Dalena Farms Inc.jpg";
import delanoFarms from "../assets/images/RefPageImages/C-Delano farms 01.jpg";
import dwelley from "../assets/images/RefPageImages/C-Dwelley Farms.jpg";
import fourStart from "../assets/images/RefPageImages/C-Four Start Fruit, Inc.jpg";
import grapeman from "../assets/images/RefPageImages/C-Grapeman Farms.jpg";
import grimes from "../assets/images/RefPageImages/C-Grimes.jpg";
import lagomarsino from "../assets/images/RefPageImages/C-Lagomarsino Farming, LLC.jpg";
// D Group
import phCompany from "../assets/images/RefPageImages/D-2PH.jpg";
import bayBaby from "../assets/images/RefPageImages/D-Bay Baby Produce.jpg";
import bobalu from "../assets/images/RefPageImages/D-Bobalu.jpg";
import brooks from "../assets/images/RefPageImages/D-Brooks Tropicals.jpg";
import bullseye from "../assets/images/RefPageImages/D-Bullseye Farms.jpg";
import burch from "../assets/images/RefPageImages/D-Burch Farms.jpg";
import delLlano from "../assets/images/RefPageImages/D-Del Llano Farms.jpg";
import diMare from "../assets/images/RefPageImages/D-DiMare companies.jpg";
import everkrisp from "../assets/images/RefPageImages/D-Everkrisp.jpg";
import goldCoast from "../assets/images/RefPageImages/D-Gold Coast Packing.jpg";
import mackFarms from "../assets/images/RefPageImages/D-Mack Farms.jpg";
import wonderfulNurseries from "../assets/images/RefPageImages/D-Wonderful Nurseries.jpg";
// E Group
import blancVineyards from "../assets/images/RefPageImages/E-Blanc Vineyards.jpg";
import buffaloRepack from "../assets/images/RefPageImages/E-Buffalo Repack LLC.jpg";
import dorsingFarms from "../assets/images/RefPageImages/E-Dorsing Farms.jpg";
import douglasFruit from "../assets/images/RefPageImages/E-Douglas Fruit Company.jpg";
import greenAcre from "../assets/images/RefPageImages/E-Green Acre Farms Inc.jpg";
import hellerBros from "../assets/images/RefPageImages/E-Heller Bros.jpg";
import inlandDesert from "../assets/images/RefPageImages/E-Inland Desert Nursery.jpg";
import internationalFarming from "../assets/images/RefPageImages/E-International Farming Corp.jpg";
import mcdougallSons from "../assets/images/RefPageImages/E-McDougall & Sons, Inc.jpg";
import newGeneration from "../assets/images/RefPageImages/E-New Generation.jpg";
import siriSon from "../assets/images/RefPageImages/E-Siri & Son.jpg";
import stadelmanFruit from "../assets/images/RefPageImages/E-Stadelman Fruit Company.jpg";
// F Group
import elkhornPacking from "../assets/images/RefPageImages/F-Elkhorn Packing Co., LLC.jpg";
import gladaway from "../assets/images/RefPageImages/F-Glad-A-Way Gardens.jpg";
import jjFamily from "../assets/images/RefPageImages/F-J&J Family of Farms.jpg";
import mrPacking from "../assets/images/RefPageImages/F-M&R Packing.jpg";
import martinezOrchards from "../assets/images/RefPageImages/F-Martinez Orchards.jpg";
import martoriFarms from "../assets/images/RefPageImages/F-Martori Farms.jpg";
import michaelWolf from "../assets/images/RefPageImages/F-Michael Wolf Vineyard Service.jpg";
import muranakaFarm from "../assets/images/RefPageImages/F-Muranaka Farm.jpg";
import ogPacking from "../assets/images/RefPageImages/F-O-G Packing.jpg";
import pacificFarm from "../assets/images/RefPageImages/F-Pacific Farm Management, Inc.jpg";
import podestaFarms from "../assets/images/RefPageImages/F-Podesta Farms.jpg";
import woodspurFarms from "../assets/images/RefPageImages/F-Woodspur Farms.jpg";
// G Group
import innovativeProduce from "../assets/images/RefPageImages/G-Innovative Produce.jpg";
import lewisTaylor from "../assets/images/RefPageImages/G-Lewis Taylor Farms.jpg";
import osr from "../assets/images/RefPageImages/G-OSR.jpg";
import pacificTomato from "../assets/images/RefPageImages/G-Pacific Tomato Growers.jpg";
import pointSal from "../assets/images/RefPageImages/G-Point Sal Packing, Inc.jpg";
import ranchoGuadalupe from "../assets/images/RefPageImages/G-Rancho Guadalupe Inc.jpg";
import rcFarms from "../assets/images/RefPageImages/G-RC Farms.jpg";
import redBlossom from "../assets/images/RefPageImages/G-Red Blossom.jpg";
import refco from "../assets/images/RefPageImages/G-Refco.jpg";
import sizemoreFarms from "../assets/images/RefPageImages/G-Sizemore Farms.jpg";
import southernValley from "../assets/images/RefPageImages/G-Southern Valley Fruit and Vegetable, Inc.jpg";
import steinbeckCountry from "../assets/images/RefPageImages/G-Steinbeck Country Produce.jpg";
// H Group
import exeterProduce from "../assets/images/RefPageImages/H-Exeter Produce.jpg";
import moonlightFamily from "../assets/images/RefPageImages/H-Moonlight family of companies.jpg";
import pacificAg from "../assets/images/RefPageImages/H-Pacific Ag Management.jpg";
import pacificFarms from "../assets/images/RefPageImages/H-Pacific Farms & Orchards, Inc.jpg";
import periSons from "../assets/images/RefPageImages/H-Peri & Sons Farms.jpg";
import primaFrutta from "../assets/images/RefPageImages/H-Prima Frutta Packing.jpg";
import regatta from "../assets/images/RefPageImages/H-Regatta Tropicals, Ltd.jpg";
import sakuma from "../assets/images/RefPageImages/H-Sakuma Brothers.jpg";
import sarabian from "../assets/images/RefPageImages/H-Sarabian Farms.jpg";
import seaside from "../assets/images/RefPageImages/H-Seaside Farm.jpg";
import topBrass from "../assets/images/RefPageImages/H-Top Brass.jpg";
import westCoastTomato from "../assets/images/RefPageImages/H-West Coast Tomato LLC.jpg";
// I Group
import sageFruit from "../assets/images/RefPageImages/I-Sage Fruit Company.jpg";
import sagemoorVineyards from "../assets/images/RefPageImages/I-Sagemoor Vineyards.jpg";
import salgadoHarvesting from "../assets/images/RefPageImages/I-Salgado Harvesting.jpg";
import sunValley from "../assets/images/RefPageImages/I-Sun Valley Orchards.jpg";
import sunDate from "../assets/images/RefPageImages/I-SunDate.jpg";
import sunripe from "../assets/images/RefPageImages/I-Sunripe Certified Brands.jpg";
import terryFarms from "../assets/images/RefPageImages/I-Terry Farms, Inc.jpg";
import titanFarms from "../assets/images/RefPageImages/I-Titan Farms.jpg";
import vanGroningen from "../assets/images/RefPageImages/I-Van Groningen & Sons, Inc.jpg";
import vannFarms from "../assets/images/RefPageImages/I-Vann Farms.jpg";
import vickFarms from "../assets/images/RefPageImages/I-Vick Farms.jpg";
import walterRawl from "../assets/images/RefPageImages/I-Walter P. Rawl & Sons.jpg";


const aGroup: ImageData[] = [
	{
		title: "Anthony Vineyards",
		source: `${anthonyVineyards}`,
		smDelay: 150,
		mdDelay: 50,
	},
	{
		title: 'Bayer',
		source: `${bayer}`,
		smDelay: 100,
		mdDelay: 150,
	},
	{
		title: 'Capay Organic Farms',
		source: `${capayOrganicFarms}`,
		smDelay: 200,
		mdDelay: 250,
	},
	{
		title: 'Grimmway Farms',
		source: `${grimmwayFarms}`,
		smDelay: 150,
		mdDelay: 100,
	},
	{
		title: 'Harris Ranch',
		source: `${harrisRanch}`,
		smDelay: 250,
		mdDelay: 200,
	},
	{
		title: 'L&M',
		source: `${LandM}`,
		smDelay: 200,
		mdDelay: 300,
	},
	{
		title: 'Olam',
		source: `${olam}`,
		smDelay: 300,
		mdDelay: 150,
	},
	{
		title: 'Planasa',
		source: `${planasa}`,
		smDelay: 250,
		mdDelay: 250,
	},
	{
		title: 'The Wonderful Company',
		source: `${wonderful}`,
		smDelay: 350,
		mdDelay: 350,
	},
];

const bGroup: ImageData[] = [
	{
		title: 'Agro Labor Services',
		source: `${agroLabor}`,
		smDelay: 0,
		mdDelay: 0,
	},
	{
		title: 'AG Socio',
		source: `${agSocio}`,
		smDelay: 100,
		mdDelay: 100,
	},
	{
		title: 'Cal Central Harvesting, Inc.',
		source: `${calCentral}`,
		smDelay: 50,
		mdDelay: 200,
	},
	{
		title: "Coastal Farm Labor Services",
		source: `${coastalFarm}`,
		smDelay: 150,
		mdDelay: 50,
	},
	{
		title: 'Corazon del Campo',
		source: `${corazonDelCampo}`,
		smDelay: 100,
		mdDelay: 150,
	},
	{
		title: 'Moonridge Management, Inc.',
		source: `${moonridge}`,
		smDelay: 200,
		mdDelay: 250,
	},
	{
		title: 'Premium Packing',
		source: `${premiumPacking}`,
		smDelay: 150,
		mdDelay: 100,
	},
	{
		title: 'Ramco',
		source: `${ramco}`,
		smDelay: 250,
		mdDelay: 200,
	},
	{
		title: 'San Lucas AG Services',
		source: `${sanLucasAg}`,
		smDelay: 200,
		mdDelay: 300,
	},
	{
		title: 'Santa Maria Harvesting',
		source: `${santaMaria}`,
		smDelay: 300,
		mdDelay: 150,
	},
	{
		title: 'Castaneda & Sons, Inc.',
		source: `${castaneda}`,
		smDelay: 250,
		mdDelay: 250,
	},
	{
		title: 'Titan AG Services',
		source: `${titan}`,
		smDelay: 350,
		mdDelay: 350,
	},
];

const cGroup: ImageData[] = [
	{
		title: 'Acquistapace Harvesting, Inc.',
		source: `${acquistapace}`,
		smDelay: 0,
		mdDelay: 0,
	},
	{
		title: 'Andy Boy',
		source: `${andyBoy}`,
		smDelay: 100,
		mdDelay: 100,
	},
	{
		title: 'Bonipak',
		source: `${bonipak}`,
		smDelay: 50,
		mdDelay: 200,
	},
	{
		title: "Cedar Point Nursery",
		source: `${cedarPoint}`,
		smDelay: 150,
		mdDelay: 50,
	},
	{
		title: 'Bayer',
		source: `${centralWest}`,
		smDelay: 100,
		mdDelay: 150,
	},
	{
		title: 'Dalena Farms',
		source: `${dalena}`,
		smDelay: 200,
		mdDelay: 250,
	},
	{
		title: 'Delano Farms',
		source: `${delanoFarms}`,
		smDelay: 150,
		mdDelay: 100,
	},
	{
		title: 'Dwelley Farms',
		source: `${dwelley}`,
		smDelay: 250,
		mdDelay: 200,
	},
	{
		title: 'Four Star Fruit, Inc.',
		source: `${fourStart}`,
		smDelay: 200,
		mdDelay: 300,
	},
	{
		title: 'Grapeman Farms',
		source: `${grapeman}`,
		smDelay: 300,
		mdDelay: 150,
	},
	{
		title: 'Grimes Produce Company',
		source: `${grimes}`,
		smDelay: 250,
		mdDelay: 250,
	},
	{
		title: 'Lagomarsino Farming, LLC.',
		source: `${lagomarsino}`,
		smDelay: 350,
		mdDelay: 350,
	},
];

const dGroup: ImageData[] = [
	{
		title: '2PH',
		source: `${phCompany}`,
		smDelay: 0,
		mdDelay: 0,
	},
	{
		title: 'Bay Baby Produce',
		source: `${bayBaby}`,
		smDelay: 100,
		mdDelay: 100,
	},
	{
		title: 'Bobalu',
		source: `${bobalu}`,
		smDelay: 50,
		mdDelay: 200,
	},
	{
		title: "Brooks Tropicals",
		source: `${brooks}`,
		smDelay: 150,
		mdDelay: 50,
	},
	{
		title: 'Bullseye Farms',
		source: `${bullseye}`,
		smDelay: 100,
		mdDelay: 150,
	},
	{
		title: 'Burch Farms',
		source: `${burch}`,
		smDelay: 200,
		mdDelay: 250,
	},
	{
		title: 'Del Llano Farms',
		source: `${delLlano}`,
		smDelay: 150,
		mdDelay: 100,
	},
	{
		title: 'DiMare Companies',
		source: `${diMare}`,
		smDelay: 250,
		mdDelay: 200,
	},
	{
		title: 'Everkrisp',
		source: `${everkrisp}`,
		smDelay: 200,
		mdDelay: 300,
	},
	{
		title: 'Gold Coast Packing',
		source: `${goldCoast}`,
		smDelay: 300,
		mdDelay: 150,
	},
	{
		title: 'Mack Farms',
		source: `${mackFarms}`,
		smDelay: 250,
		mdDelay: 250,
	},
	{
		title: 'Wonderful Nurseries',
		source: `${wonderfulNurseries}`,
		smDelay: 350,
		mdDelay: 350,
	},
];

const eGroup: ImageData[] = [
	{
		title: 'Blanc Vineyards',
		source: `${blancVineyards}`,
		smDelay: 0,
		mdDelay: 0,
	},
	{
		title: 'Buffalo Repack LLC',
		source: `${buffaloRepack}`,
		smDelay: 100,
		mdDelay: 100,
	},
	{
		title: 'Dorsing Farms',
		source: `${dorsingFarms}`,
		smDelay: 50,
		mdDelay: 200,
	},
	{
		title: 'Green Acre Farms, Inc.',
		source: `${greenAcre}`,
		smDelay: 100,
		mdDelay: 150,
	},
	{
		title: 'Heller Bros',
		source: `${hellerBros}`,
		smDelay: 200,
		mdDelay: 250,
	},
	{
		title: 'Inland Desert Nursery',
		source: `${inlandDesert}`,
		smDelay: 150,
		mdDelay: 100,
	},
	{
		title: 'International Farming Corp',
		source: `${internationalFarming}`,
		smDelay: 250,
		mdDelay: 200,
	},
	{
		title: 'New Generation',
		source: `${newGeneration}`,
		smDelay: 300,
		mdDelay: 150,
	},
	{
		title: 'Siri & Son',
		source: `${siriSon}`,
		smDelay: 250,
		mdDelay: 250,
	},
];

const fGroup: ImageData[] = [
	{
		title: 'Elkhorn Packing Co., LLC',
		source: `${elkhornPacking}`,
		smDelay: 0,
		mdDelay: 0,
	},
	{
		title: 'Glad-A-Way Gardens',
		source: `${gladaway}`,
		smDelay: 100,
		mdDelay: 100,
	},
	{
		title: 'J&J Family of Farms',
		source: `${jjFamily}`,
		smDelay: 50,
		mdDelay: 200,
	},
	{
		title: "M&R Packing",
		source: `${mrPacking}`,
		smDelay: 150,
		mdDelay: 50,
	},
	{
		title: 'Martinez Orchards',
		source: `${martinezOrchards}`,
		smDelay: 100,
		mdDelay: 150,
	},
	{
		title: 'Martori Farms',
		source: `${martoriFarms}`,
		smDelay: 200,
		mdDelay: 250,
	},
	{
		title: 'Michael Wolf Vineyard Service',
		source: `${michaelWolf}`,
		smDelay: 150,
		mdDelay: 100,
	},
	{
		title: 'Muranaka Farm',
		source: `${muranakaFarm}`,
		smDelay: 250,
		mdDelay: 200,
	},
	{
		title: 'O-G Packing',
		source: `${ogPacking}`,
		smDelay: 200,
		mdDelay: 300,
	},
	{
		title: 'Pacific Farm Management',
		source: `${pacificFarm}`,
		smDelay: 300,
		mdDelay: 150,
	},
	{
		title: 'Podesta Farms',
		source: `${podestaFarms}`,
		smDelay: 250,
		mdDelay: 250,
	},
	{
		title: 'Woodspur Farms',
		source: `${woodspurFarms}`,
		smDelay: 350,
		mdDelay: 350,
	},
];

const gGroup: ImageData[] = [
	{
		title: 'Innovative Produce',
		source: `${innovativeProduce}`,
		smDelay: 0,
		mdDelay: 0,
	},
	{
		title: 'Lewis Taylor Farms',
		source: `${lewisTaylor}`,
		smDelay: 100,
		mdDelay: 100,
	},
	{
		title: 'OSR',
		source: `${osr}`,
		smDelay: 50,
		mdDelay: 200,
	},
	{
		title: "Pacific Tomato Growers",
		source: `${pacificTomato}`,
		smDelay: 150,
		mdDelay: 50,
	},
	{
		title: 'Point Sal Packing, Inc.',
		source: `${pointSal}`,
		smDelay: 100,
		mdDelay: 150,
	},
	{
		title: 'Rancho Guadalupe, Inc.',
		source: `${ranchoGuadalupe}`,
		smDelay: 200,
		mdDelay: 250,
	},
	{
		title: 'RC Farms',
		source: `${rcFarms}`,
		smDelay: 150,
		mdDelay: 100,
	},
	{
		title: 'Red Blossom',
		source: `${redBlossom}`,
		smDelay: 250,
		mdDelay: 200,
	},
	{
		title: 'Refco',
		source: `${refco}`,
		smDelay: 200,
		mdDelay: 300,
	},
	{
		title: 'Sizemore Farms',
		source: `${sizemoreFarms}`,
		smDelay: 300,
		mdDelay: 150,
	},
	{
		title: 'Southern Valley Fruit and Vegetable, Inc.',
		source: `${southernValley}`,
		smDelay: 250,
		mdDelay: 250,
	},
	{
		title: 'Steinbeck Country Produce',
		source: `${steinbeckCountry}`,
		smDelay: 350,
		mdDelay: 350,
	},
];

const hGroup: ImageData[] = [
	{
		title: 'Exeter Produce',
		source: `${exeterProduce}`,
		smDelay: 0,
		mdDelay: 0,
	},
	{
		title: 'Moonlight Family of Companies',
		source: `${moonlightFamily}`,
		smDelay: 100,
		mdDelay: 100,
	},
	{
		title: 'Pacific AG Management',
		source: `${pacificAg}`,
		smDelay: 50,
		mdDelay: 200,
	},
	{
		title: "Pacific Farms & Orchards, Inc.",
		source: `${pacificFarms}`,
		smDelay: 150,
		mdDelay: 50,
	},
	{
		title: 'Peri & Sons Farms',
		source: `${periSons}`,
		smDelay: 100,
		mdDelay: 150,
	},
	{
		title: 'Prima Frutta Packing',
		source: `${primaFrutta}`,
		smDelay: 200,
		mdDelay: 250,
	},
	{
		title: 'Regatta Tropicals, Ltd.',
		source: `${regatta}`,
		smDelay: 150,
		mdDelay: 100,
	},
	{
		title: 'Sakuma Brothers',
		source: `${sakuma}`,
		smDelay: 250,
		mdDelay: 200,
	},
	{
		title: 'Sarabian Farms',
		source: `${sarabian}`,
		smDelay: 200,
		mdDelay: 300,
	},
	{
		title: 'Seaside Farm',
		source: `${seaside}`,
		smDelay: 300,
		mdDelay: 150,
	},
	{
		title: 'Top Brass',
		source: `${topBrass}`,
		smDelay: 250,
		mdDelay: 250,
	},
	{
		title: 'West Coast Tomato, LLC',
		source: `${westCoastTomato}`,
		smDelay: 350,
		mdDelay: 350,
	},
];

const iGroup: ImageData[] = [
	{
		title: 'Salgado Harvesting',
		source: `${salgadoHarvesting}`,
		smDelay: 50,
		mdDelay: 200,
	},
	{
		title: 'SunDate',
		source: `${sunDate}`,
		smDelay: 100,
		mdDelay: 150,
	},
	{
		title: 'Sunripe Certified Brands',
		source: `${sunripe}`,
		smDelay: 200,
		mdDelay: 250,
	},
	{
		title: 'Terry Farms, Inc.',
		source: `${terryFarms}`,
		smDelay: 150,
		mdDelay: 100,
	},
	{
		title: 'Titan Farms',
		source: `${titanFarms}`,
		smDelay: 250,
		mdDelay: 200,
	},
	{
		title: 'Van Groningen & Sons, Inc.',
		source: `${vanGroningen}`,
		smDelay: 200,
		mdDelay: 300,
	},
	{
		title: 'Vann Farms',
		source: `${vannFarms}`,
		smDelay: 300,
		mdDelay: 150,
	},
	{
		title: 'Vick Farms',
		source: `${vickFarms}`,
		smDelay: 250,
		mdDelay: 250,
	},
	{
		title: 'Walter P. Rawl & Sons',
		source: `${walterRawl}`,
		smDelay: 350,
		mdDelay: 350,
	},
];

export const ReferenceImages = {
	aGroup, bGroup, cGroup, dGroup, eGroup,
	fGroup, gGroup, hGroup, iGroup,
};