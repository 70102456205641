import React from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import { TabViewProps } from './TabTypes';
import {Wrapper} from "../../components/Wrapper";
import {useTabStyles} from "./TabStyles";

interface TabPanelProps {
	tab: TabViewProps;
	index: any;
	value: any;
}

export const TabPanel = ({ value, index, tab }: TabPanelProps) => {
	const classes = useTabStyles();
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			style={{ width: "100%" }}
		>
			{value === index && (
				<Box p={0}>
					<Wrapper p={0} m={0}>
						<Grid item xs={12}>
							<Typography gutterBottom variant='h5' align='center' className={classes.tabTitle}>
								{tab.title}
							</Typography>
							{/*<Typography gutterBottom variant='h6' align='center' className={classes.tabBody}>*/}
							{/*	{tab.body}*/}
							{/*</Typography>*/}
						</Grid>
						<Grid item xs={12}>
							{tab.component}
						</Grid>
					</Wrapper>
				</Box>
			)}
		</div>
	);
};
