import React from 'react';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Box, Grid, Typography} from '@material-ui/core';
import {InfoBoxImage} from '../static';
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoParagraph: {
      marginBottom: '10px',
    },
    infoTitle: {
      marginBottom: '50px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '20px',
      },
    },
    infoBoxImg: {
      margin: '0 0 0 auto',
      padding: '0',
      maxHeight: '300px',
      maxWidth: '300px',
      [theme.breakpoints.down('md')]: {
        margin: '0 auto',
      },
    },
    textContainer: {
      margin: 'auto',
    },
    logoBox: {
      width: '100%',
      height: '100%',
      padding: 0,
    },
  })
)

export const InfoBox = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <Box p={[3, 5, 3, 5]} mb={3}>
        <Grid container justify={'center'}>
          <Typography variant="h2" align={"center"} className={classes.infoTitle} gutterBottom>Why Choose PET TIGER?</Typography>
        </Grid>
        <Grid container spacing={4} alignContent="space-around" >

          <Grid item xs={12} md={3} lg={3} className={classes.logoBox} >
            <CardMedia component="img" image={InfoBoxImage.source} className={classes.infoBoxImg} />
          </Grid>

          <Grid item xs={12} md={8} lg={8} className={classes.textContainer}>
            <Typography variant="subtitle1" color="textSecondary" align={isMd ? "left" : "center"} gutterBottom className={classes.infoParagraph}>
              {`Whatever labor force size you manage, adopt Pet Tiger, and watch your operation’s effortless 
              transition to paperless and wireless interaction. PET Tiger makes it possible for real-time 
              labor-force decisions and assist in compliance with all labor laws.`}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" align={isMd ? "left" : "center"} gutterBottom className={classes.infoParagraph}>
              {`PET Tiger is the most versatile real-time management tool for Growers, Shippers, Packers, Processors, 
              Labor Contractors, Cold Storage, Administration, Nurseries and Food Companies. One stop shop for all your 
              Labor Force Attendance, Productivity and Compliance needs!`}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" align={isMd ? "left" : "center"} gutterBottom className={classes.infoParagraph}>
              {`Join the thousands of Pet Tiger users that are communicating via personal mobile devices to track labor 
              activities, including attendance, job assignment, location, productivity, work-order implementations, 
              mapping, logistics, equipment utilization, and lots more.`}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" align={isMd ? "left" : "center"} gutterBottom className={classes.infoParagraph}>
              {`For better communication between desk-bound employees and their counterparts in the field. When your 
              remote workforce has access to Pet Tiger, you will maintain accuracy, achieve compliance, and benefit 
              from greater transparency – all in real time. For all crops!`}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" align={isMd ? "left" : "center"} gutterBottom className={classes.infoParagraph}>
              {`We look forward to work with you on this important project.`}
            </Typography>
          </Grid>

        </Grid>
      </Box>
    </div>
  );
}