import React from 'react';
import {
	Box,
	Grid,
	GridProps,
} from '@material-ui/core';

interface Props extends GridProps {
	m?: number | number[] | string;
	p?: number | number[] | string;
	mt?: number | string;
	pt?: number | string;
	mb?: number | string;
	pb?: number | string;
	mr?: number | string;
	pr?: number | string;
	ml?: number | string;
	pl?: number | string;
	mx?: number | string;
	px?: number | string;
	my?: number | string;
	py?: number | string;
	className?: string;
	gridClassName?: string;
	clone?: boolean;
	children: React.ReactNode | React.ReactNode[];
	gridWrapper?: boolean;
}

export const Wrapper = ({ xs, ...props }: Props) => (
	<Box
		p={props.p}
		m={props.m}
		mt={props.mt}
		pt={props.pt}
		mb={props.mb}
		pb={props.pb}
		mr={props.mr}
		pr={props.pr}
		ml={props.ml}
		pl={props.pl}
		mx={props.mx}
		px={props.px}
		my={props.my}
		py={props.py}
		className={props.className}
		clone={props.clone}>
		<Grid
			container
			xs={xs}
			className={props.gridClassName}
			spacing={props.spacing}
			justify={props.justify}
			alignContent={props.alignContent}
			alignItems={props.alignItems}
			direction={props.direction}
		>
			{props.gridWrapper
				? <div className='ag-theme-material' style={{ height: '500px', width: '100%' }}>
					{props.children}
				</div>
				: props.children
			}
		</Grid>
	</Box>
);

