import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Typography,
  Box,
  GridList,
  GridListTile,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { VideoCard } from '../components';
import { FieldVideos } from '../static';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      marginRight: '10%',
      marginLeft: '10%',
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        marginLeft: 0,
      },
    },
    gridList: {
      // flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
      overflow: 'hidden',
    },
    gridItem: {
      minHeight: '350px',
      padding: 20,
    },
  })
);

export const VideoGallery = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div style={{ backgroundColor: '#FFFFFF' }}>
      <Box p={[3, 5, 3, 5]} m={3}>
        <div className='container-fluid lg-p-top'>
          <Typography
            gutterBottom
            variant='h2'
            align='center'
            className='lg-mg-bottom'
            style={{ marginBottom: '50px' }}
          >
            PET Tiger in Action
          </Typography>
          <div className={classes.root}>
            <GridList
              className={classes.gridList}
              cols={isXs ? 1 : isSm ? 2 : isMd ? 3 : 3}
              spacing={20}
            >
              {FieldVideos.map(({ title, source, mdDelay, smDelay }, idx) => (
                  source.length > 0 ? 
                <GridListTile
                  key={idx}
                  data-aos='zoom-in-up'
                  data-aos-delay={isMd ? mdDelay : smDelay}
                  className={classes.gridItem}
                >
                  <VideoCard source={source} title={title} />
                </GridListTile>
                  : <GridListTile
                  key={idx}
                  data-aos='zoom-in-up'
                  data-aos-delay={isMd ? mdDelay : smDelay}
                  className={classes.gridItem}
                />
              ))}
            </GridList>
          </div>
        </div>
      </Box>
    </div>
  );
};
