import pick1 from "../assets/images/pick1.jpg";
import pick2 from "../assets/images/pick2.jpg";
import iphone2 from "../assets/images/iphone2.jpg";
import petiphone from "../assets/images/petiphone.jpg";
import handheld from "../assets/images/handheld.jpg";
import boxScan from "../assets/images/box-scan.jpg";
import partners from "../assets/images/partners.jpg";
import petIcons from "../assets/images/peticons.jpg";

import printingInTheField from "../assets/images/PrintingInTheField.jpg"
import youChoose from "../assets/images/YouChoose.jpg"
import aFaceOfIrisOrBoth from "../assets/images/AFaceOrIrisOrBoth.png"
import petKiosk from "../assets/images/PETKiosk.jpg"
import petIrisAndFaceIdClock from "../assets/images/PETIrisAndFaceIDClock.jpg"
import petFingerClock from "../assets/images/PETFingerClock.jpg"

export type ImageData = {
  title: string,
  source: string,
  mdDelay: number
  smDelay: number
}


export const GridImages: ImageData[] = [
   //Line-2
  {
    title: "Quickly Scan and Record",
    source: `${boxScan}`,
    smDelay: 150,
    mdDelay: 50,
  },
  {
    title: "Intuitive Mobile App",
    source: `${handheld}`,
    smDelay: 100,
    mdDelay: 150,
  },
  {
    title: "Monitor Progress in Real-Time",
    source: `${iphone2}`,
    smDelay: 200,
    mdDelay: 250,
  },

  //Line-3
  {
    title: "Printing in the Field",
    source: `${printingInTheField}`,
    smDelay: 250,
    mdDelay: 100,
  },
  {
    title: "You Choose...",
    source: `${youChoose}`,
    smDelay: 350,
    mdDelay: 200,
  },
  {
    title: "A Face or Iris or Both",
    source: `${aFaceOfIrisOrBoth}`,
    smDelay: 300,
    mdDelay: 300,
  },

  //Line-4
  {
    title: "PET Kiosk",
    source: `${petKiosk}`,
    smDelay: 350,
    mdDelay: 150,
  },
  {
    title: "PET Iris and Face ID Clock",
    source: `${petIrisAndFaceIdClock}`,
    smDelay: 450,
    mdDelay: 250,
  },
  {
    title: "PET Finger Clock",
    source: `${petFingerClock}`,
    smDelay: 400,
    mdDelay: 350,
  },
];

export const InfoBoxImage: ImageData = {
    title: "PET Suite of Products",
    source: `${petIcons}`,
    smDelay: 0,
    mdDelay: 0,
  };
  
  export const ContactImage2: ImageData = {
    title: "PET Tiger",
    source: `${petiphone}`,
    smDelay: 0,
    mdDelay: 0,
};
