import React from "react";
import {TabViewProps} from "./TabTypes";
import {TabGallery} from "./TabGallery";
import {ReferenceImages} from "../../static";

export const TabContent: TabViewProps[] = [
	{
		label: "Large Co",
		title: "Large Companies",
		body: "A Group description.",
		component: <TabGallery images={ReferenceImages.aGroup} />,
		order: 0,
	},
	{
		label: "Contractors",
		title: "Labor Contractors",
		body: "B Group description.",
		component: <TabGallery images={ReferenceImages.bGroup} />,
		order: 1,
	},
	{
		label: "Growers",
		title: "Growers",
		body: "C Group description.",
		component: <TabGallery images={ReferenceImages.cGroup} />,
		order: 2,
	},
	{
		label: "Farms",
		title: "Farms",
		body: "D Group description.",
		component: <TabGallery images={ReferenceImages.dGroup} />,
		order: 3,
	},
	{
		label: "Shippers",
		title: "Shippers",
		body: "E Group description.",
		component: <TabGallery images={ReferenceImages.eGroup} />,
		order: 4,
	},
	{
		label: "Nurseries",
		title: "Nurseries",
		body: "F Group description.",
		component: <TabGallery images={ReferenceImages.fGroup} />,
		order: 5,
	},
	{
		label: "Packers",
		title: "Packers",
		body: "G Group description.",
		component: <TabGallery images={ReferenceImages.gGroup} />,
		order: 6,
	},
	{
		label: "Management",
		title: "Management",
		body: "H Group description.",
		component: <TabGallery images={ReferenceImages.hGroup} />,
		order: 7,
	},
	{
		label: "Processors",
		title: "Processors",
		body: "I Group description.",
		component: <TabGallery images={ReferenceImages.iGroup} />,
		order: 8,
	},
];
