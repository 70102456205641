import React from "react";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import {useTabStyles} from "./TabStyles";

export const TabHeader = () => {
	const classes = useTabStyles();
	return (
		<div style={{ backgroundColor: '#FFFFFF' }}>
			<Box p={[3, 5, 3, 5]} m={3}>
				<Typography gutterBottom variant='h3' align='center' className={classes.headerMain}>
					PET Tiger will transform your businesses
				</Typography>
				<Typography gutterBottom variant='h6' align='center' className={classes.headerSubtitle}>
					It is critically necessary to optimize your operation’s resources, mainly your labor force.
				</Typography>
				<Typography gutterBottom variant='h6' align='center' className={classes.headerBody}>
					PET Tiger is the most versatile real-time management tool for Growers, Shippers, Packers, Processors,
					Labor Contractors, Cold Storage, Administration, Nurseries and Food Companies. One stop shop for all
					your Labor Force Attendance, Productivity and Compliance needs! Each of our clients can customize their
					PET Tiger module configuration and hardware selection to meet their specific needs.
				</Typography>
				<Typography gutterBottom variant='h5' align='center' className={classes.headerAction}>
					View some of our Clients below...
				</Typography>
			</Box>
		</div>
	)
};
