import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import {Contact, Features, Footer, Landing, ImageGallery, Navbar, VideoGallery, InfoBox} from "./index";
import {TabContainer} from "../pages/TabContainer";

export const App = () => {
  return (
    <Router>
      <Navbar />
        <Switch>
          <Route exact path="/">
            <Landing />
            <ImageGallery />
            <VideoGallery />
            <Features />
            <InfoBox />
            <Contact />
          </Route>
          <Route exact path='/contact'>
            <Redirect to='/#contact' />
          </Route>
          <Route exact path='/references'>
            <TabContainer />
            <Contact />
          </Route>
          <Route>
            <Redirect to='/' />
          </Route>
        </Switch>
      <Footer />
    </Router>
  );
}

