import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import Box from '@material-ui/core/Box';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { ImageModal } from '../components/ImageModal';
import { GridImages } from '../static';
import { useMediaQuery } from '@material-ui/core';
import { theme } from '../assets/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      marginRight: '10%',
      marginLeft: '10%',
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        marginLeft: 0,
      },
    },
    gridList: {
      // flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
      overflow: 'hidden',
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.6) 30%, rgba(0,0,0,0.6) 80%, rgba(0,0,0,0) 100%)',
    },
    gridItem: {
      minHeight: '250px',
      padding: 20,
    },
  })
);

export const ImageGallery = () => {
  const classes = useStyles();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div style={{ backgroundColor: '#FFFFFF' }}>
      <Box p={[3, 5, 3, 5]} m={3}>
        <div className={classes.root}>
          <GridList
            className={classes.gridList}
            cols={isXs ? 1 : isSm ? 2 : isMd ? 3 : 3}
            spacing={20}
          >
            {GridImages.map(({ title, source, mdDelay, smDelay }, idx) => (
              <GridListTile
                key={idx}
                data-aos='zoom-in-up'
                data-aos-delay={isMd ? mdDelay : smDelay}
                className={classes.gridItem}
              >
                <img src={source} alt={title} />
                <GridListTileBar
                  title={title}
                  classes={{
                    root: classes.titleBar,
                    title: classes.title,
                  }}
                  actionIcon={
                    <ImageModal title={title} source={source} />
                  }
                />
              </GridListTile>
            ))}
          </GridList>
        </div>
      </Box>
    </div>
  );
};
