import React, {memo} from "react";
import {useMediaQuery} from "@material-ui/core";
import {GridList, GridListTile, GridListTileBar} from "@material-ui/core";
import {ImageModal} from "../../components";
import {useTabStyles} from "./TabStyles";
import {theme} from "../../assets/theme";
import {ImageData} from "../../static"

interface TabGalleryProps {
	images: ImageData[];
}

export const TabGallery = memo(({ images }: TabGalleryProps) => {
	const classes = useTabStyles();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));
	const isXs = useMediaQuery(theme.breakpoints.down('xs'));

	return (
		<div className={classes.gridContainer}>
			<GridList
				className={classes.gridList}
				cols={isXs ? 1 : isSm ? 2 : isMd ? 3 : 3}
				spacing={20}
			>
				{images.map(({ title, source, mdDelay, smDelay }, idx) => (
					<GridListTile
						key={idx}
						data-aos='zoom-in-up'
						data-aos-delay={250 + (isMd ? mdDelay : smDelay)}
						className={classes.gridItem}
					>
						<img src={source} alt={title} />
						<GridListTileBar
							title={title}
							classes={{
								root: classes.gridTitleBar,
								title: classes.gridTitle,
							}}
							actionIcon={
								<ImageModal title={title} source={source} />
							}
						/>
					</GridListTile>
				))}
			</GridList>
		</div>
	);
});
