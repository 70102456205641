import React from "react";
import {TabView} from "./TabView";
import {TabHeader} from "./TabHeader";
import {TabContent} from "./TabContent";

export const TabContainer = () => {
	return (
		<div>
			<TabHeader />
			<TabView content={TabContent}/>
		</div>
	)
};
